@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
/*
  Index.css:
  - Description: This is the formatting file of index.js component
*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*
  App.css:
  - Description: This is the formatting file of App.js component
*/
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*
  ConfirmUser.css:
  - Description: This is the formatting file of ConfirmUser.js component
*/

.page-wrapper {
  position: relative;
  margin: auto;
  height: 100vh;
  width: 100vw;
  background: #ffffff;
}

.verification-message {
  position: absolute;
  width: 381px;
  height: 46px;
  left: calc(50% - 381px / 2 - 0.5px);
  top: 255px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 46px;
  text-transform: uppercase;
  color: #000000;
}
.verification-details {
  position: absolute;
  width: 600px;
  height: 37px;
  left: calc(50% - 600px / 2);
  top: 494px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 27px;
  line-height: 37px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}

@media screen and (max-width: 500px) {
  .verification-message {
    height: 27px;
    left: 75px;
    right: 76px;
    top: 225px;
    font-size: 24px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    width: unset !important;
  }

  .verification-details {
    height: 46px;
    left: 50px;
    right: 50px;
    top: 450px;
    font-size: 19px;
    line-height: 23px;
    width: unset !important;
  }
}

@media screen and (max-width: 325px) {
  .page-wrapper {
    height: 700px;
  }
}

/*
  PageHeader.css:
  - Description: This is the formatting file of PageHeader.js component
*/
.page-banner {
  position: relative;
  height: 155px;
  background-image: url(https://i.sibel.health/care/anne-care-page-header-scaled.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.app-logo {
  content: url("https://i.sibel.health/care/anne-care-logo.svg");
  position: absolute;
  width: 147px;
  height: 54px;
  left: calc(50% - 147px / 2 - 0.5px);
  top: calc(50% - 54px / 2 + 17.5px);
}

.bottom-line {
  position: absolute;
  left: 29.17%;
  right: 29.17%;
  top: 100%;
  bottom: 0%;
  border: 1px solid #e1e3e3;
}

@media screen and (max-width: 500px) {
  .page-banner {
    height: 90px;
    background-image: url(https://i.sibel.health/care/anne-care-page-header-mobile.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .app-logo {
    width: 99.6px;
    height: 37.2px;
    left: calc(50% - 99.6px / 2);
    bottom: 14px;
    top: unset !important;
  }

  .bottom-line {
    width: 288px;
    height: 0px;
    left: calc(50% - 288px / 2 + 0.5px);
    bottom: 0px;
    border: 0.5px solid #e1e3e3;
    right: unset !important;
    top: unset !important;
  }
}

/*
  PageFooter.css:
  - Description: This is the formatting file of PageFooter.js component
*/
.page-footer {
  position: absolute;
  width: 344px;
  height: 58px;
  left: calc(50% - 344px / 2);
  bottom: 56px;
}
.footer-logo {
  content: url("https://i.sibel.health/care/Sibel_Logo.svg");
  position: absolute;
  width: 107px;
  height: 30px;
  left: calc(50% - 107px / 2 + 0.5px);
  bottom: 28px;
}
.footer-details {
  position: absolute;
  width: 344px;
  height: 22px;
  left: calc(50% - 344px / 2);
  bottom: 0px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #101010;
}
@media screen and (max-width: 500px) {
  .page-footer {
    width: 258px;
    height: 57px;
    left: calc(50% - 258px / 2 - 0.5px);
    bottom: 36px;
  }

  .footer-logo {
    width: 85.2px;
    height: 22.8px;
    left: calc(50% - 85.2px / 2 + 0.5px);
    bottom: 16px;
  }

  .footer-details {
    width: 258px;
    height: 16px;
    left: calc(50% - 258px / 2);
    top: calc(50% - 16px / 2 + 20.5px);
    font-size: 12px;
    line-height: 16px;
    bottom: unset !important;
  }
}

