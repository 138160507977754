/*
  PageHeader.css:
  - Description: This is the formatting file of PageHeader.js component
*/

@import url("https://fonts.googleapis.com/css?family=Open+Sans");
.page-banner {
  position: relative;
  height: 155px;
  background-image: url(https://i.sibel.health/care/anne-care-page-header-scaled.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.app-logo {
  content: url("https://i.sibel.health/care/anne-care-logo.svg");
  position: absolute;
  width: 147px;
  height: 54px;
  left: calc(50% - 147px / 2 - 0.5px);
  top: calc(50% - 54px / 2 + 17.5px);
}

.bottom-line {
  position: absolute;
  left: 29.17%;
  right: 29.17%;
  top: 100%;
  bottom: 0%;
  border: 1px solid #e1e3e3;
}

@media screen and (max-width: 500px) {
  .page-banner {
    height: 90px;
    background-image: url(https://i.sibel.health/care/anne-care-page-header-mobile.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .app-logo {
    width: 99.6px;
    height: 37.2px;
    left: calc(50% - 99.6px / 2);
    bottom: 14px;
    top: unset !important;
  }

  .bottom-line {
    width: 288px;
    height: 0px;
    left: calc(50% - 288px / 2 + 0.5px);
    bottom: 0px;
    border: 0.5px solid #e1e3e3;
    right: unset !important;
    top: unset !important;
  }
}
