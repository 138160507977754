/*
  ConfirmUser.css:
  - Description: This is the formatting file of ConfirmUser.js component
*/

@import url("https://fonts.googleapis.com/css?family=Open+Sans");

.page-wrapper {
  position: relative;
  margin: auto;
  height: 100vh;
  width: 100vw;
  background: #ffffff;
}

.verification-message {
  position: absolute;
  width: 381px;
  height: 46px;
  left: calc(50% - 381px / 2 - 0.5px);
  top: 255px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 46px;
  text-transform: uppercase;
  color: #000000;
}
.verification-details {
  position: absolute;
  width: 600px;
  height: 37px;
  left: calc(50% - 600px / 2);
  top: 494px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 27px;
  line-height: 37px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}

@media screen and (max-width: 500px) {
  .verification-message {
    height: 27px;
    left: 75px;
    right: 76px;
    top: 225px;
    font-size: 24px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    width: unset !important;
  }

  .verification-details {
    height: 46px;
    left: 50px;
    right: 50px;
    top: 450px;
    font-size: 19px;
    line-height: 23px;
    width: unset !important;
  }
}

@media screen and (max-width: 325px) {
  .page-wrapper {
    height: 700px;
  }
}
