/*
  PageFooter.css:
  - Description: This is the formatting file of PageFooter.js component
*/

@import url("https://fonts.googleapis.com/css?family=Open+Sans");
.page-footer {
  position: absolute;
  width: 344px;
  height: 58px;
  left: calc(50% - 344px / 2);
  bottom: 56px;
}
.footer-logo {
  content: url("https://i.sibel.health/care/Sibel_Logo.svg");
  position: absolute;
  width: 107px;
  height: 30px;
  left: calc(50% - 107px / 2 + 0.5px);
  bottom: 28px;
}
.footer-details {
  position: absolute;
  width: 344px;
  height: 22px;
  left: calc(50% - 344px / 2);
  bottom: 0px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #101010;
}
@media screen and (max-width: 500px) {
  .page-footer {
    width: 258px;
    height: 57px;
    left: calc(50% - 258px / 2 - 0.5px);
    bottom: 36px;
  }

  .footer-logo {
    width: 85.2px;
    height: 22.8px;
    left: calc(50% - 85.2px / 2 + 0.5px);
    bottom: 16px;
  }

  .footer-details {
    width: 258px;
    height: 16px;
    left: calc(50% - 258px / 2);
    top: calc(50% - 16px / 2 + 20.5px);
    font-size: 12px;
    line-height: 16px;
    bottom: unset !important;
  }
}
